body{
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;

}



.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: #18292e;
}

.top-wrap{
  
  
}

.content-wrap{
  margin-top: 25px;
  
}

.content-wrap h4{
  margin-bottom: 25px;
}

.content-wrap a:visited{
  color: #e384e3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#main-header{
  height: 300px;
  background-color: #65cadc;
  position: relative;
  font-family: "Rokkitt", serif;
  /* font-optical-sizing: auto; */
  font-weight: 700;
  font-style: normal;
  color: white;
  text-shadow: #277887 2px 2px 0;
}

body .menu-bar{
  background-color: #18292e;
  background-image: none;
}

.header-text{
  position: relative;
  z-index: 999;
}

#main-header-title{
  color: white;
  font-size: 3em;
  position: absolute;
  left: 48px; 
  bottom: 20px;
  color: white;

}

#top-wrap h2, #top-wrap h3, #top-wrap h4, #top-wrap h5, #top-wrap h6{
  margin-top: 0;
}

#main-header .header-bg1{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

#main-header h2{line-height: 1em; margin: 0.8em 0 0.5em 0;}

#main-content{
  padding: 1px;
  color: #ffffff;
  /* border-top: solid 6px #18292e; */
  background-color: #234954;
  background-image: url('img/footer-gradient.png');
  background-position: bottom;
  background-repeat: repeat-x;
}

#main-header .header-bg1 img{
  height: 100%;
  width: auto;
  position: absolute;
  left:0;
}

#main-header .header-bg2{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

#main-header .header-bg2 img{
  height: 100%;
  width: auto;
  position: absolute;
  right:0;
}

body h1, body h2, body h3, body h4, body h5, body h6{
  font-family: "Rokkitt", serif;
  font-weight: 700;
  font-style: normal;
}


.our-names{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rokitt;
}

.our-names h2{ font-size: 2.5em; }
.our-names h4{ font-size: 1.5em; }
.our-names h2, .our-names h4{
  padding: 0 8px;
  margin-bottom: 0;
}

.date{
  font-size: 1.8em;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}


#main-footer{
  background-color: #18292e;
  height: 150px;
  background-image: url('img/footer-right.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 150px;
  color: white;
}

.captcha{
  display: none;
}

.architects-daughter-regular {
  font-family: "Architects Daughter", cursive;
  font-weight: 400;
  font-style: normal;
}

.opening-message{
  box-shadow: #18292e 4px 4px 4px;
  text-align: left;
  padding: 24px;
  background-color: #1c3e48;
  max-width: 800px;
  margin: 20pt auto 20pt auto;
  font-size: 16pt;
  margin-bottom: 88px;
}

.litebox-overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: #000000ba;
  align-items: center;
  z-index: 5000;
}

#main-content a, #main-content a:visited, .text-link{
  color: #65cadc;
  text-decoration: underline;
  cursor: pointer;
}

#main-content a:hover, .text-link:hover{
  color: #d637d6;
}

.MuiPaper-root.menu-bar a:visited{
  color: white;
}

.litebox{
  max-width: 90%;
  max-height: 90%;
  padding: 32px 9px 9px 9px;
  background: white;
  height: auto;
  position: relative;
  cursor: pointer;
}

.litebox-overlay img{
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  object-fit: cover;
  align-self: center;
}
.litebox-close{
  color: white;
  position: fixed;
  right: 0;
  top: 0;
  background: #000000ba;
  font-size: 24px;
  cursor: pointer;
}
.MuiImageListItem-root{
  cursor: pointer;
}

/* for screens 850px wide or smaller media query */
@media screen and (max-width: 850px) {
  .mobile-only{
    display: block;
  }
  .desktop-only{
    display: none;
  }
}
/* for screens 851px wide or larger */
@media screen and (min-width: 851px) {
  .mobile-only{
    display: none;
  }
  .desktop-only{
    display: block;
  }
}